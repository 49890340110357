import React, { type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ResumeFunnelButton } from '@hungryroot/funnel';
import { ArrowRightNew } from '@hungryroot/icons';
import styles from './CtaButton.module.css';

interface Props {
  isVisible: boolean;
  text: ReactNode;
  className?: string;
}

export function CtaButton(props: Props) {
  const { className, isVisible, text } = props;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, bottom: '-100px' }}
          animate={{ opacity: 1, bottom: '16px' }}
          exit={{ opacity: 0, bottom: '-100px' }}
          transition={{ duration: 0.45 }}
          className={styles['container']}
        >
          <ResumeFunnelButton>
            {({ route }) => {
              return (
                <a href={route} className={`${styles.button} ${className}`}>
                  <AnimatePresence>
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.25, delay: 0.4 }}
                      className={styles['text-wrapper']}
                    >
                      {text}
                    </motion.span>
                  </AnimatePresence>
                  <ArrowRightNew />
                </a>
              );
            }}
          </ResumeFunnelButton>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
